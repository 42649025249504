import axios from "axios";

const api = axios.create({
    baseURL: "https://steelytoe.com/dev.titudev.com/api/v1/resources",
    timeout: 10000, // Set a timeout for the request (optional)
});

const fetchGallery = async (eventName, location, pageNumber, bnib = null) => {
    try {
        const params = {
            "filter[mftgEventName]": eventName,
            ...(location && !bnib ? { "filter[mftgPhotoLocation]": location } : {}),
            ...(bnib ? { "pageNumber": pageNumber } : { "pageNumber": pageNumber || 1 }),
            pageSize: 20,
            ...(bnib ? { "filter[mftgDescription][like]": `%${bnib}%` } : {}),
        };

        const response = await api.get("/master_file_tagging", { params });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.warn("Data not found (404):", error);
            return null;
        } else {
            console.error("Error fetching gallery data:", error);
            throw error;
        }
    }
};

export default fetchGallery;
