/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState, useRef } from "react";

import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'

import GalleryLoading from "../../components/shared/GalleryLoading";
import galleryConfigs from "../../constant/gallery";
import fetchGallery from "../../helper/getGallery";

export default function Index() {
    const config2022 = galleryConfigs.galleryConfig2022;
    const config2023 = galleryConfigs.galleryConfig2023;
    const config2024 = galleryConfigs.galleryConfig2024;

    const [galleryData, setGalleryData] = useState([]);
    const [totalGalleryData, setTotalGalleryData] = useState(null);

    const [isLoading, setLoading] = useState(false);
    const [activeYear, setActiveYear] = useState(2024);
    const [configSelected, setConfigSelected] = useState(config2024);
    const [locationOption, setLocationOption] = useState(configSelected.locations);
    const [activeLocation, setActiveLocation] = useState({
        year: activeYear,
        value: "",
    });

    // eslint-disable-next-line no-unused-vars
    const [pageNumber, setPageNumber] = useState(1);
    const [inputValue, setInputValue] = useState(""); // Menyimpan value langsung dari input
    const [endOfPage, setEndOfPage] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isEmptiedSearch, setIsEmptiedSearch] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(""); // Menyimpan value dengan delay
    const [isComingSoon, setIsComingSoon] = useState(false);

    const btnClass =
        "bg-neutral-900 border border-neutral-700 px-6 py-3 rounded-lg hover:bg-neutral-700 transition-all duration-500";

    const switchYear = async (year) => {
        setPageNumber(1);
        setGalleryData([]);
        setActiveYear(year);
        setInputValue("");
        setEndOfPage(false);
        setIsDirty(false);
        setIsEmptiedSearch(false);
        setActiveLocation({
            year: year,
            value: "",
        });

        if (year === 2022) {
            setIsComingSoon(false);
            setConfigSelected(config2022);
            setLocationOption(config2022.locations);
        } else if (year === 2023) {
            setIsComingSoon(false);
            setConfigSelected(config2023);
            setLocationOption(config2023.locations);
        } else if (year === 2024) {
            setIsComingSoon(false);
            setConfigSelected(config2024);
            setLocationOption(config2024.locations);
        }
    };

    const switchLocation = async (year, value) => {
        setPageNumber(1);
        setGalleryData([]);
        setInputValue("");
        setEndOfPage(false);
        setActiveLocation({
            year: year,
            value: value,
        });
    };

    const handleChange = (e) => {
        if (e.target.value === "" && debouncedValue !== "") {
            setIsEmptiedSearch(true);
            setIsDirty(false);
        } else if (!isDirty) {
            setIsDirty(true);
            setIsEmptiedSearch(false);
        }
        setInputValue(e.target.value);
        setEndOfPage(false);
        setPageNumber(1);
    };

    const handleScroll = () => {
        if (document.body.scrollHeight - 500 < window.scrollY + window.innerHeight) {
            console.log({
                totalGalleryData,
                galleryData: galleryData.length
            })
            if (totalGalleryData === galleryData.length || endOfPage) {
                // do nothing
            } else {
                setLoading(true);
            }
        }
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return function(...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    window.addEventListener("scroll", debounce(handleScroll, 300));

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (inputValue !== debouncedValue && inputValue !== "") {
                setGalleryData([]);
            }
            setDebouncedValue(inputValue);
        }, 500);
        return () => clearTimeout(delayDebounce);
    }, [inputValue]);

    useEffect(() => {
        if (debouncedValue !== "") {
            setActiveLocation({
                year: activeYear,
                value: ""
            });
            fetchData();
        } else if (debouncedValue === "" && inputValue === "" && isDirty) {
            fetchData();
        } else if (isEmptiedSearch) {
            fetchData();
            setActiveLocation({
                year: activeYear,
                value: ""
            });
        }
    }, [debouncedValue]);

    useEffect(() => {
        if (isLoading === true && galleryData.length > 0 && !isEmptiedSearch) {
            setPageNumber((prevPage) => prevPage + 1);
        }
    }, [isLoading]);

    useEffect(() => {
        if (pageNumber !== 1) fetchData();
    }, [pageNumber]);

    useEffect(() => {
        fetchData();
    }, [activeYear, activeLocation, configSelected]);

    const fetchData = async () => {
        if (isLoading === false && endOfPage === false) setLoading(true);

        if (galleryData.length > 0 && totalGalleryData === galleryData.length) {
            setEndOfPage(true);
            return;
        }

        const response = await fetchGallery(
            configSelected.eventName,
            activeLocation?.value,
            pageNumber,
            inputValue
        );

        if (response === null) {
            setGalleryData([]);
            setTotalGalleryData(0);
        } else {
            setTotalGalleryData(response.status.totalRecords);
            let tempGalleryData = isEmptiedSearch ? [] : galleryData;
            response.data.forEach((item) => {
                const checkDuplicate = tempGalleryData.filter((tempItem) => tempItem.id === item.id);
                if (checkDuplicate.length === 0) {
                    tempGalleryData.push(item);
                }
            });
            setGalleryData(tempGalleryData);
        }
        setLoading(false);
    };

    return (
        <div className="bg-black relative">
            <div className="flex mx-auto items-start justify-start z-10 min-h-screen 2xl:pt-56 md:pt-40 pt-24 w-full bg-black bg-pageTitle bg-no-repeat">
                <div className="2xl:px-0 md:px-14 px-0 w-full py-4">
                    <div className="w-full font-beVietnam text-white">
                        <div className="container h-full mx-auto w-full">
                            <div className="w-full mb-8">
                                <div className="h-full space-y-4">
                                    <div className="flex md:flex-row flex-col items-center justify-between md:gap-y-0 gap-y-6">
                                        <h1 className="2xl:text-5xl text-3xl uppercase font-bold max-w-2xl italic">
                                            Race Gallery
                                        </h1>
                                        <div className="space-x-4">
                                            <button
                                                onClick={() => switchYear(2022)}
                                                className={`${btnClass} ${
                                                    activeYear === 2022 ? "bg-red-700" : null
                                                }`}
                                            >
                                                2022
                                            </button>
                                            <button
                                                onClick={() => switchYear(2023)}
                                                className={`${btnClass} ${
                                                    activeYear === 2023 ? "bg-red-700" : null
                                                }`}
                                            >
                                                2023
                                            </button>
                                            <button
                                                onClick={() => switchYear(2024)}
                                                className={`${btnClass} ${
                                                    activeYear === 2024 ? "bg-red-700" : null
                                                }`}
                                            >
                                                2024
                                            </button>
                                        </div>
                                    </div>
                                    <div className="md:px-0 px-6">
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            className="bg-neutral-950 block border border-neutral-600 w-full 2xl:py-6 2xl:px-6 p-4 rounded-lg placeholder:text-neutral-600"
                                            placeholder="Search your BIB here"
                                            value={inputValue}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-wrap justify-center gap-4 md:px-0 px-2 font-bold text-lg">
                                        {totalGalleryData === 0 && <span>Image not found with specified keyword.</span>}
                                        {totalGalleryData > 0 && <span>{totalGalleryData} photo(s) uploaded.</span>}
                                    </div>
                                    <div className="flex flex-wrap justify-center gap-4 md:px-0 px-2">
                                        {!isComingSoon &&
                                            locationOption.map((value, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() =>
                                                    {
                                                        if (activeLocation.value === value) {
                                                            switchLocation(activeLocation, "")
                                                        } else {
                                                            switchLocation(activeLocation, value)
                                                        }
                                                    }
                                                    }
                                                    className={`${btnClass} ${
                                                        activeLocation?.value === value
                                                            ? "bg-red-700"
                                                            : null
                                                    }`}
                                                >
                                                    {value}
                                                </button>
                                            ))}
                                    </div>
                                    <div className="p-5 sm:p-8">
                                        {isComingSoon ? (
                                            <h1 className="text-center font-bold italic">
                                                Coming Soon
                                            </h1>
                                        ) : (
                                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 lg:grid-cols-4">
                                                    <Gallery options={{
                                                        padding: { top: 20, bottom: 40, left: 10, right: 10 },
                                                    }}>
                                                        {galleryData.map((item, index) =>
                                                            <div className="rounded-lg" key={index}>
                                                                <Item key={index} original={item.mftgUrl} thumbnail={`${item.mftgUrl}?x-oss-process=image/resize,w_480/format,jpg`} width={JSON.parse(item.mftgMeta).width} height={JSON.parse(item.mftgMeta).height}>
                                                                    {({ ref, open }) => (
                                                                        <img ref={ref} onClick={open} src={`${item.mftgUrl}?x-oss-process=image/resize,w_480/format,jpg`} alt={item.mftgDescription} className="rounded-lg cursor-zoom-in" />
                                                                    )}
                                                                </Item>
                                                            </div>
                                                        )}
                                                    </Gallery>
                                                    {isLoading === true && endOfPage === false && <GalleryLoading />}
                                            </div>
                                        )}
                                    </div>
                                    {endOfPage && <p className="text-center font-bold text-lg">You have reach the end of page.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
