import { PhotoIcon } from "@heroicons/react/24/solid";
import React from "react";

export default function GalleryLoading() {
    return (
        <>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
            <div className="w-full bg-neutral-900 animate-pulse flex items-center justify-center py-32 rounded-lg border border-neutral-800">
                <PhotoIcon width={96} className="text-neutral-700" />
            </div>
        </>
    );
}
